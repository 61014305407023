<template>
  <div class="framePage-scroll">
    <div class="ovy-a">
      <el-form ref="Form" :model="Form" :rules="rules" label-width="120px">
        <h3 class="textBt">基本信息</h3>
        <el-form-item label="班级名称：" prop="projectName" class="form-item">
          <el-input v-model="Form.projectName" placeholder="请输入班级名称" :disabled="disabled" size="small" maxlength="50"
            show-word-limit />
        </el-form-item>
        <el-form-item label="行政区划：" prop="areaId" class="form-item">
          <el-cascader v-model="Form.areaId" :options="areatreeList" :props="propsarea" :disabled="disabled" clearable
            filterable @change="areaChange" size="small"></el-cascader>
        </el-form-item>
        <el-form-item label="有效日期：" prop="Effectivedate" class="form-item">
          <el-date-picker size="small" :disabled="disabledcc" v-model="Form.Effectivedate" type="daterange"
            range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
            value-format="timestamp"></el-date-picker>
        </el-form-item>
        <el-form-item label="报名截止时间：" prop="deadline" class="form-item">
          <el-date-picker size="small" :disabled="signEndDateDisabled" v-model="Form.deadline" type="date"
            format="yyyy-MM-dd" value-format="timestamp" placeholder="选择日期"></el-date-picker>
        </el-form-item>
        <el-form-item label="负责人：" prop="projectUser" class="form-item">
          <el-input v-model="Form.projectUser" placeholder="请输入负责人" :disabled="disabled" size="small" />
        </el-form-item>
        <el-form-item label="负责人电话：" prop="projectUserPhone" class="form-item">
          <el-input v-model="Form.projectUserPhone" placeholder="请输入负责人电话" maxlength="12" :disabled="disabled"
            size="small" />
        </el-form-item>
        <el-form-item label="培训类型：" prop="Trainingtype" class="form-item">
          <el-input v-if="['20', '30'].includes(auditState) || projectSource == '20'" v-model="Form.Trainingtype"
            type="text" size="small" :disabled="true" />
          <tree v-else ref="tree" @eventBtn="childBack" :ruleForm="Form" size="small" @clearParams="clearParams" modal
            type typeStu />
        </el-form-item>
        <el-form-item label="培训人数" prop="projectPeople" class="form-item" v-if="selectType != '0'" required>
          <el-input-number v-model="Form.projectPeople" @change="handleChange" :min="1" style="width: 100%"
            :disabled="disabled" size="small"></el-input-number>
        </el-form-item>

        <el-form-item label="期次" prop="projectPc" class="form-item" v-if="selectType != '0'">
          <el-input type="text" v-model="Form.projectPc" :disabled="disabled" size="small"></el-input>
        </el-form-item>
        <el-form-item label="人群类型" prop="projectCrowd" class="form-item" v-if="selectType == '4'">
          <el-select v-model="Form.projectCrowd" placeholder="请选择" :disabled="disabled" size="small">
            <el-option v-for="item in CrowdtypeList" :key="item.value" :label="item.label"
              :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="付款形式" prop="paymentMode" class="form-item">
          <el-select v-model="Form.paymentMode" placeholder="请选择" size="small" disabled>
            <el-option v-for="item in paymentModeList" :key="item.value" :label="item.label"
              :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="第三方预付机构" prop="paymentCompId" class="form-item" v-if="Form.paymentMode == 20">
          <el-select size="small" v-model="Form.paymentCompId" remote :remote-method="getCompanyList" filterable
            clearable placeholder="请至少输入两个字搜索" style="width: 100%" disabled>
            <el-option v-for="item in CompanyList" :key="item.compId" :label="item.compName"
              :value="item.compId"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="启用新上传资料" label-width="200" prop="paraIsMaterial" class="form-item" v-if="Form.paymentMode == 20">
          <el-radio-group v-model="Form.paraIsMaterial">
            <el-radio :label="true">是</el-radio>
            <el-radio :label="false">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="课程分成金额" prop="paymentCommission" class="form-item" v-if="Form.paymentMode == 20">
          <el-input-number class="input_1" v-model="Form.paymentCommission" :min="0"
            :precision="2"></el-input-number>
        </el-form-item>
        <h3 class="textBt">基本设置</h3>
        <el-form-item cla label="允许学员查看学时证明" label-width="200" prop="projectClassHourCertificate" class="form-item">
          <el-radio-group v-model="Form.projectClassHourCertificate">
            <el-radio :label="true">是</el-radio>
            <el-radio :label="false">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item cla label="审核事由" prop="auditReason" class="form-item" v-show="Form.auditState == '40'">
          <el-input class="textarea_1" type="textarea" disabled v-model="Form.auditReason"></el-input>
        </el-form-item>
      </el-form>
      <div class="bottom-btn">
        <el-button @click="cancle" class="bgc-bv">取消</el-button>
        <el-button @click="seveData('Form')" class="bgc-bv">保存</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import UploadPic from "@/mixins/UploadPic";
import DatePicker from "@/mixins/DatePicker";
import tree from "@/components/treePopup";
import pdf from "pdfobject";
import { mapGetters } from "vuex";
import List from "@/mixins/List";
export default {
  name: "TestPaper",
  components: {
    tree,
  },
  mixins: [UploadPic, DatePicker, List],
  data() {
    var projectUserPhone = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入负责人电话"));
      } else if (
        value &&
        !/^1[3456789]\d{9}$/.test(value) &&
        !/^([0-9]{3,4}-)?[0-9]{7,8}$/.test(value)
      ) {
        callback(new Error("请输入正确的电话号码"));
      } else {
        callback();
      }
    };

    return {
      projectId: "",
      auditState: "",
      projectSource: "",
      disabled: false,
      disabledcc: false, // 是否禁止更改有效期
      signEndDateDisabled: false, // 是否禁止报名截止时间
      areatreeList: [], // 行政区划 - 下拉数据
      // 区划 - 格式字段
      props: {
        value: "id",
        label: "label",
      },
      // 区划 - 字段配置
      propsarea: {
        value: "value",
        label: "label",
        emitPath: false,
        checkStrictly: true,
      },
      CrowdtypeList: [], // 人群类型 - 下拉数据
      paymentModeList: [], // 付款形式 - 下拉数据
      CompanyList: [], // 第三方预付机构 - 下拉数据
      selectType: "0", // 培训类型的相关参数，我也不知道具体是啥，反正老代码写的（最好别动）
      Form: {
        projectId: "",
        projectName: "",
        areaId: "",
        Effectivedate: [],
        deadline: "",
        projectUser: "",
        projectUserPhone: "",
        projectPeople: "",
        projectAddress: "",
        projectPc: "",
        projectPaperComp: "",
        Examinationtime: "",
        proType: "",
        thumbnail: "",
        fileKey: "",
        Trainingtype: "",
        projectClassHourCertificate: true,
        enableSurvey: false,
        auditState: "",
        auditReason: "",
        spotCheckNum: "",
        delay: false,
        chatFileKeys: [],
        trainMethod: "",
        uploadfileList: [],
        paymentMode:"10", // 付款形式
        paymentCompId: "", // 第三方预付机构
        paraIsMaterial: false, // 启用新上传资料
        paymentCommission:"", // 课程分成金额
      },
      params: {},
      rules: {
        projectName: [
          { required: true, message: "请输入班级名称", trigger: "blur" },
        ],
        areaId: [
          { required: true, message: "请选择行政区划", trigger: "change" },
        ],
        Effectivedate: [
          { required: true, message: "请选择有效时间", trigger: "change" },
        ],
        deadline: [
          { required: true, message: "请选择报名截止时间", trigger: "blur" },
        ],
        projectUser: [
          { required: true, message: "请输入负责人", trigger: "blur" },
        ],
        projectUserPhone: [
          { required: true, validator: projectUserPhone, trigger: "blur" },
        ],
        projectCrowd: [
          { required: true, message: "请选择人群类型", trigger: "change" },
        ],
        Trainingtype: [
          {
            required: true,
            message: "请选择培训类型",
            trigger: ["change", "blur"],
          },
        ],
        paymentCommission: [
          { required: true, message: "请输入课程分成金额", trigger: "blur" },
        ],
      },
    };
  },
  created() {
    this.projectId = sessionStorage.getItem("projectId");
    this.auditState = sessionStorage.getItem("auditState");
    this.projectSource = sessionStorage.getItem("projectSource");
    if (this.projectSource == "20") {
      this.disabled = true;
      this.disabledcc = true;
    }
    this.getInvoice_type();
    this.getareatree();
    if (['20', '25', '30'].includes(this.auditStat)) {
      this.disabled = true;
      this.disabledcc = true;
    }
    this.loadData(this.projectId);
  },
  computed: {
    ...mapGetters({
      userJson: "getUser",
    }),
  },
  methods: {
    // 获取字典
    getInvoice_type() {
      // 人群类型
      const CrowdtypeList = this.$setDictionary("CROWDTYPE", "list");
      for (const key in CrowdtypeList) {
        this.CrowdtypeList.push({
          value: key,
          label: CrowdtypeList[key],
        });
      }
      // 付款形式
      const paymentModeList = this.$setDictionary("PROJECT_PAYMENT_MODE", "list");
      for (const key in paymentModeList) {
        this.paymentModeList.push({
          value: key,
          label: paymentModeList[key],
        });
      }
    },
    // 获取 - 行政区划
    getareatree() {
      this.$post("/sys/area/tree").then((ret) => {
        this.areatreeList = ret.data;
      });
    },
    // 行政区划change事件
    areaChange(val) {
      if (val.indexOf("41") == -1) {
        this.Form.trainMethod = "10";
      } else {
        this.Form.trainMethod = "";
      }
    },
    // 培训类型 - 确定
    childBack(params) {
      this.params = { ...params };
      this.selectType = this.params.trainFormIdPath;
    },
    // 培训类型 - 取消
    clearParams() {
      this.params = {};
    },
    // 获取机构 - 两个字查询
    getCompanyList(query) {
      if (query.trim().length >= 2) {
        this.$post("/sys/company/queryCompanyList", { compName: query })
          .then((res) => {
            if (res.status == 0) {
              this.CompanyList = res.data || [];
            }
          })
          .catch(() => {
            return;
          });
      } else {
        this.CompanyList = [];
      }
    },

    // 获取班级详情
    loadData(projectId) {
      this.$post("/biz/project/query", { projectId })
        .then((ret) => {
          let data = ret.data;
          let res = JSON.stringify(data);
          res = res.replace(/areaId/g, "value");
          res = res.replace(/areaName/g, "label");
          res = res.replace(/trainTypeId/g, "id");
          res = JSON.parse(res);
          this.id = res.id;
          this.selectType = res.idPath.substr(0, 1);
          this.Form = {
            projectId: res.projectId,
            projectName: res.projectName,
            Effectivedate: [res.startDateLong, res.endDateLong],
            areaId: res.value,
            deadline: res.signEndDateLong,
            projectUser: res.projectUser,
            projectUserPhone: res.projectUserPhone,
            projectPaperDate: res.projectPaperDateLong,
            projectPaperComp: res.projectPaperComp,
            projectPc: res.projectPc,
            projectAddress: res.projectAddress,
            projectPeople: res.projectPeople,
            thumbnail: res.applicationFormUrl,
            projectCrowd: res.projectCrowd,
            fileKey: res.applicationForm,
            projectClassHourCertificate: res.projectClassHourCertificate,
            enableSurvey: res.enableSurvey,
            auditState: res.auditState,
            auditReason: res.auditReason,
            projectSource: res.projectSource || "",
            trainFormId: res.id,
            trainTypeNamePath: res.trainTypeNamePath,
            spotCheckNum: res.spotCheckNum,
            delay: res.delay,
            chatFileKeys: res.eduProjectFileList || [],
            trainMethod: res.trainMethod,
            uploadfileList: res.list || [],
            paymentMode: res.paymentMode, // 付款形式
            paymentCompId: res.paymentCompId, // 第三方预付机构
            paraIsMaterial: res.paraIsMaterial, // 启用新上传资料
            paymentCommission: res.paymentCommission, // 课程金额分成
          };
          let name1 = "";
          let name2 = "";
          let name3 = "";
          let name4 = "";
          let name5 = "";
          if (res.codeBo.trainTypeNamePath) {
            name1 = res.codeBo.trainTypeNamePath;
            this.params.trainFormId = res.codeBo.id;
            this.Form.trainFormId = res.codeBo.id;
          }
          if (res.codeBo.postName) {
            name2 = "/" + res.codeBo.postName;
            this.params.postFormId = res.codeBo.postId;
          }
          if (res.codeBo.industryNamePath) {
            name3 = "/" + res.codeBo.industryNamePath;
            this.params.industryFormId = res.codeBo.industryId;
          }
          if (res.codeBo.occupationNamePath) {
            name4 = "/" + res.codeBo.occupationNamePath;
            this.params.occFormId = res.codeBo.occupationId;
          }
          if (res.codeBo.trainLevelName) {
            name5 = "/" + res.codeBo.trainLevelName;
            this.params.levelFormId = res.codeBo.trainLevelId;
          }
          let name = name1 + name2 + name3 + name4 + name5;
          name = name.replace(/,/g, "-");
          this.Form.Trainingtype = name;
          var timestamp = new Date().getTime();
          if (Number(res.endDateLong) > Number(timestamp)) {
            this.signEndDateDisabled = false;
          } else {
            this.signEndDateDisabled = true;
          }
          this.getCompanyList(res.paymentCompName);
        })
        .catch((err) => {
          return;
        });
    },
    // 保存
    seveData() {
      const el = this.$refs.Form;
      const el1 = this.$refs.Form1;
      el.validate((valid) => {
        if (valid) {
          if (this.Form.enableSurvey && this.params.trainFormId == "11") {
            el1.validate((valid1) => {
              if (valid1) {
                this.doAjax();
              }
            });
          } else if (
            !this.Form.enableSurvey &&
            this.params.trainFormId == "11"
          ) {
            this.doAjax();
          } else if (
            this.Form.areaId.indexOf("130") != -1 &&
            this.params.trainFormId == "5"
          ) {
            if (this.Form.uploadfileList.length < 1) {
              this.$confirm("开班申请表未上传，是否上传？", "提示", {
                confirmButtonText: "是",
                cancelButtonText: "否",
                showClose: false,
                closeOnClickModal: false,
                type: "warning",
              })
                .then(() => { })
                .catch(() => {
                  this.doAjax();
                });
            } else {
              this.doAjax();
            }
          } else {
            this.doAjax();
          }
        }
      });
    },
    // 保存方法
    doAjax() {
      let parmar = {
        projectName: this.Form.projectName,
        areaId: this.Form.areaId,
        startDate: this.Form.Effectivedate[0],
        endDate: this.Form.Effectivedate[1],
        projectUser: this.Form.projectUser,
        projectUserPhone: this.Form.projectUserPhone,
        projectPeople: this.Form.projectPeople,
        projectClassHourCertificate: this.Form.projectClassHourCertificate,
        trainMethod: "10",
        paymentCompId:  this.Form.paymentCompId, // 第三方预付机构
        paraIsMaterial: this.Form.paraIsMaterial, // 启用新上传资料
        paymentCommission: this.Form.paymentCommission, // 启用新上传资料
        projectClassHourCertificate: this.Form.projectClassHourCertificate, //是否允许打印学时证明
        trainMethod: this.Form.trainMethod, //培训方式默认线上
      };
      if (this.Form.deadline) {
        parmar.signEndDate = this.Form.deadline;
      }

      if (this.Form.projectCrowd) {
        parmar.projectCrowd = this.Form.projectCrowd;
      }
      if (this.Form.projectId) {
        parmar.projectId = this.Form.projectId;
      }
      if (this.Form.projectPc) {
        parmar.projectPc = this.Form.projectPc;
      }
      if (this.params.trainFormId) {
        parmar.trainTypeId = this.params.trainFormId;
      }
      if (this.params.levelFormId) {
        parmar.trainLevelId = this.params.levelFormId;
      }
      if (this.params.occFormId) {
        parmar.occupationId = this.params.occFormId;
      }
      if (this.params.postFormId) {
        parmar.postId = this.params.postFormId;
      }
      if (this.params.industryFormId) {
        parmar.industryId = this.params.industryFormId;
      }
      if (this.Form.projectSource) {
        parmar.projectSource = this.Form.projectSource;
      }
      this.$post("/biz/project/hospital/update", parmar)
        .then((ret) => {
          if (ret.status == 0) {
            this.$message({
              message: ret.message,
              type: "success",
            });
            const name = "classListBj";
            this.$router.push({
              name: name,
              params: {
                refresh: true,
                isAdd: false,
              },
            });
          }
        })
        .catch((err) => {
          return;
        });
    },
    // 取消
    cancle() {
      this.$router.push({
        path: "/web/classListBj",
      });
    },
  },
};
</script>
<style lang="less" scoped>
.el-form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .textBt {
    width: 100%;
    margin-bottom: 20px;
  }

  .form-item {
    width: 45%;
  }
}

.textarea_1 {
  .el-textarea__inner {
    resize: none;
  }
}

.bottom-btn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0;
}
</style>