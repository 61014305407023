<template>
  <div>
    <div class="studentlist">
      <div
        class="
          operationControl
          flexdc
          operationControlCurr
          operationControlWidth
        "
        style="align-items: flex-start"
      >
        <div class="searchbox" style="padding-left: 0.5rem">
          <div title="姓名" class="searchboxItem ci-full">
            <span class="itemLabel" style="min-width: 7rem">姓名:</span>
            <el-input
              v-model="userName"
              type="text"
              size="small"
              placeholder="请输入"
              clearable
            />
          </div>
          <div title="身份证号" class="searchboxItem ci-full">
            <span class="itemLabel" style="min-width: 7rem">身份证号:</span>
            <el-input
              v-model="idcard"
              type="text"
              size="small"
              placeholder="请输入"
              clearable
            />
          </div>
          <div title="手机号" class="searchboxItem ci-full">
            <span class="itemLabel" style="min-width: 7rem">手机号:</span>
            <el-input
              v-model="mobile"
              type="text"
              size="small"
              placeholder="请输入"
              clearable
            />
          </div>
          <div title="实名认证" class="searchboxItem ci-full">
            <span class="itemLabel" style="min-width: 6rem">实名认证:</span>
            <el-select
              v-model="authenticationState"
              placeholder="请选择"
              clearable
              size="small"
            >
              <el-option
                v-for="item in authenticationList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </div>
        </div>
        <div class="searchbox" style="padding-left: 0.5rem; margin-top: 10px">
          <div title="学时学习状态" class="searchboxItem ci-full">
            <span class="itemLabel" style="min-width: 7rem">学时学习状态:</span>
            <el-select
              v-model="completeState"
              placeholder="请选择"
              clearable
              size="small"
            >
              <el-option
                v-for="item in complete"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </div>
          <div title="入班时间" class="searchboxItem ci-full">
            <span class="itemLabel" style="min-width: 7rem">入班时间:</span>
            <el-date-picker
              size="small"
              v-model="date"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
            ></el-date-picker>
          </div>
          <div>
            <el-button type="primary" class="bgc-bv" round @click="getData()"
              >查询</el-button
            >
            <el-button class="bgc-bv" round @click="exportClassStudent"
              >导出班级学员</el-button
            >
          </div>
        </div>
      </div>
    </div>

    <div class="framePage-scroll">
      <div class="ovy-a">
        <el-table
          ref="multipleTable"
          :data="tableData"
          :height="tableHeight"
          size="small"
          tooltip-effect="dark"
          style="width: 100%"
          :header-cell-style="tableHeader"
          stripe
        >
          <el-table-column
            label="序号"
            align="center"
            type="index"
            :index="indexMethod"
            fixed
          />
          <el-table-column
            label="姓名"
            align="center"
            show-overflow-tooltip
            prop="userName"
          />
          <el-table-column
            label="身份证号"
            align="center"
            show-overflow-tooltip
            prop="idcard"
            width="160"
          />
          <el-table-column
            label="手机号"
            align="center"
            show-overflow-tooltip
            prop="mobile"
            width="100"
          />
          <el-table-column
            label="实名认证"
            align="center"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <span>
                {{ $setDictionary("AUTHENTICATION", scope.row.authentication) }}
              </span>
            </template>
          </el-table-column>
          <el-table-column
            label="要求总学时"
            align="center"
            show-overflow-tooltip
            prop="must"
          />
          <el-table-column label="已完成学时" align="center">
            <template slot-scope="scope">
              <span>{{ scope.row.study }}</span>
            </template>
          </el-table-column>
          <el-table-column label="学时学习状态" align="center" minWidth="120">
            <template slot-scope="scope">
              <span v-if="scope.row.studyState != '40'">
                {{ $setDictionary("LEARNINGSTATE", scope.row.studyState) }}
              </span>
              <el-tooltip
                v-else
                class="item"
                effect="dark"
                :content="scope.row.notCompleteReasons"
                placement="top"
              >
                <span
                  style="
                    cursor: pointer;
                    display: flex;
                    justify-content: center;
                  "
                  >{{
                    $setDictionary("LEARNINGSTATE", scope.row.studyState)
                  }}</span
                >
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column
            label="操作人"
            align="center"
            show-overflow-tooltip
            prop="fullname"
          />
          <el-table-column
            label="入班时间"
            align="center"
            show-overflow-tooltip
            prop="createTime"
            width="200"
          >
            <template slot-scope="scope">
              {{ scope.row.createTime | moment }}
            </template>
          </el-table-column>
          <el-table-column
            label="操作"
            align="center"
            :width="trainTypeId == 11 ? '380px' : '380px'"
            fixed="right"
          >
            <div slot-scope="scope" class="flexcc">
              <el-button
                type="text"
                size="mini"
                style="padding: 0 5px"
                @click="
                  lookstudent(
                    scope.row.projectUserCourseId,
                    scope.row.authentication,
                    'edit'
                  )
                "
                >查看</el-button
              >
              <el-button
                type="text"
                size="mini"
                style="padding: 0 5px"
                v-if="projectSource != '60'"
                :disabled="
                  scope.row.studyState == '30' ||
                      auditState != '10' &&
                      auditState != '40' &&
                      auditState != '45'
                "
                @click="handleDel(scope.row.userId)"
                >从本班移除</el-button
              >
              <el-button
                v-if="scope.row.auditState !== '30'"
                type="text"
                size="mini"
                style="padding: 0 5px"
                @click="Learningrecords(scope.row)"
                >学习记录</el-button
              >
            </div>
          </el-table-column>
          <Empty slot="empty" />
        </el-table>
      </div>
    </div>
    <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
    <el-dialog
      :title="dialogTitle"
      :visible.sync="dialogVisible"
      width="55%"
      top="1%"
      :center="true"
      :before-close="doClose"
      :close-on-click-modal="false"
    >
      <div class="ovy-a" style="height: 500px">
        <el-form
          label-width="120px"
          ref="Form"
          :model="Form"
          :rules="rules"
          disabled
        >
          <el-form-item label="学员姓名" prop="studentname">
            <el-input
              v-model="Form.studentname"
              type="text"
              size="small"
              placeholder="请输入学员姓名"
            />
          </el-form-item>
          <el-form-item label="身份证号" prop="idCard">
            <el-input
              v-model="Form.idCard"
              type="text"
              size="small"
              placeholder="请输入身份证号"
            />
          </el-form-item>
          <el-form-item label="性别" prop="sex" required>
            <el-radio-group v-model="Form.sex" disabled>
              <el-radio label="1">男</el-radio>
              <el-radio label="2">女</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="联系电话" prop="mobile" class="Classitem">
            <el-input
              v-model="Form.mobile"
              type="text"
              size="small"
              placeholder="请输入联系电话"
            />
          </el-form-item>
          <el-form-item label="文化水平" prop="educationLevel">
            <el-select
              v-model="Form.educationLevel"
              filterable
              clearable
              placeholder="请选择文化水平"
              size="small"
            >
              <el-option
                v-for="item in educationList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <!-- <span
        slot="footer"
        class="dialog-footer"
        style="display: flex; justify-content: center"
      >
        <el-button class="bgc-bv" @click="cancleForm('Form')">取消</el-button>
        <el-button class="bgc-bv" @click="getSure('Form')">确定</el-button>
      </span> -->
    </el-dialog>
  </div>
</template>
<script>
const className = "eduCertService";
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { mapGetters } from "vuex";
export default {
  name: "myresourseList",
  components: {
    Empty,
    PageNum,
  },
  props: ["length"],
  mixins: [List],
  data() {
    return {
      date: [],
      stu: "add",

      idcard: "",
      userName: "",
      mobile: "",
      completeState: "",
      complete: [],
      authenticationList: [],
      authenticationState: "",
      educationList: [],
      companyList: [],
      Form: {
        studentname: "",
        idCard: "",
        sex: "1",
        mobile: "",
        email: "",
        company: "",
        compId: "",
        educationLevel: "",
      },
      rules: {
        studentname: [
          { required: true, message: "请输入学员姓名", trigger: "blur" },
        ],
        zjlx: [
          { required: true, message: "请选择证件类型", trigger: "change" },
        ],
        idCard: [
          { required: true, validator: this.$validateIdCard, trigger: "blur" },
        ],
        sex: [{ required: true, message: "请选择性别", trigger: "change" }],
        mobile: [
          { required: true, validator: this.$validatePhone, trigger: "blur" },
        ],
        Coursestudy: [
          { required: true, message: "请至少选择一项课程", trigger: "change" },
        ],
        newCoursestudy: [
          { required: true, message: "请至少选择一项课程", trigger: "change" },
        ],
        workingArea: [
          { required: true, message: "请选择工作区域", trigger: "change" },
        ],
        workUnit: [
          { required: true, message: "请输入工作单位", trigger: "blur" },
        ],
        studentType: [
          { required: true, message: "请选择学员类型", trigger: "change" },
        ],
        //  profession: [
        //     { required: true, message: "请输入专业", trigger: "blur" },
        //   ],
        //   professionTitle: [
        //     { required: true, message: "请输入职称", trigger: "blur" },
        //   ],
        //   department: [
        //     { required: true, message: "请输入部门", trigger: "blur" },
        //   ],
        //   workType: [
        //     { required: true, message: "请输入工种", trigger: "blur" },
        //   ],
        //   userType: [
        //     { required: true, message: "请选择人员类型", trigger: "change" },
        //   ],
        // portCoursestudy: [
        //   { required: true, message: "请至少选择一项课程", trigger: "change" },
        // ],
      },
      activeName: "first",
      props: {
        value: "id",
        label: "label",
        emitPath: false,
      },
      projectName: "",
      courseCount: 0,
      signEndDateLong: "",
      auditState: "",
      nationList: [], // 民族数据
      studentTypeList: [], //学员类型
      areatreeList: [], //工作区划
      propsarea: {
        value: "value",
        label: "label",
        emitPath: false,
        checkStrictly: false,
      },
    };
  },
  created() {
    this.signEndDateLong = sessionStorage.getItem("signEndDateLong");
    const query = this.$route.query;
    if (query.active == "three") {
      this.openOnce();
    }
  },
  computed: {
    ...mapGetters({
      downloadItems: "getDownloadItems",
    }),
  },
  methods: {
    // 每天弹一次  别删
    openOnce() {
      //每天首次进入的时候弹提示--------- 开始
      let userId = JSON.parse(sessionStorage.getItem("userJson")).userId;
      let user = JSON.parse(localStorage.getItem(userId)) || {};
      //获取当前时间
      let date = new Date();
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let day = date.getDate();
      if (month < 10) {
        month = "0" + month;
      }
      if (day < 10) {
        day = "0" + day;
      }
      let nowDate = year + "-" + month + "-" + day;
      // console.log(localStorage.getItem(userId));

      if (!localStorage.getItem(userId)) {
        console.log("1");
        this.$confirm(
          "根据有关部门业务开展需要，相关数据会涉及个人信息、照片等敏感信息，导出数据仅限于业务范围内使用，不得用于其他用途，如因个人原因导致数据泄露，由个人承担法律责任。",
          "提示",
          {
            showClose: false, //是否显示右上角关闭按钮
            showCancelButton: false, //是否显示取消按钮
            closeOnClickModal: false, //是否可通过点击遮罩关闭 MessageBox
            confirmButtonText: "同意并继续",
            confirmButtonClass: "bgc-bv",
          }
        )
          .then(() => {
            let userKey = {};
            userKey["StudentList"] = nowDate;
            localStorage.setItem(userId, JSON.stringify(userKey));
          })
          .catch(() => {});
      } else if (user.StudentList == undefined || user.StudentList < nowDate) {
        console.log("2");
        this.$confirm(
          "根据有关部门业务开展需要，相关数据会涉及个人信息、照片等敏感信息，导出数据仅限于业务范围内使用，不得用于其他用途，如因个人原因导致数据泄露，由个人承担法律责任。",
          "提示",
          {
            showClose: false, //是否显示右上角关闭按钮
            showCancelButton: false, //是否显示取消按钮
            closeOnClickModal: false, //是否可通过点击遮罩关闭 MessageBox
            confirmButtonText: "同意并继续",
            confirmButtonClass: "bgc-bv",
          }
        )
          .then(() => {
            let userKey = JSON.parse(localStorage.getItem(userId));
            userKey.StudentList = nowDate;
            localStorage.setItem(userId, JSON.stringify(userKey));
          })
          .catch(() => {});
      }
      //每天首次进入的时候弹提示-------- 结束
    },
    // 截取字符串前几位
    getString2(str, lengthNum) {
      return str.substr(0, lengthNum);
    },
    init() {
      this.projectId = sessionStorage.getItem("projectId");
      this.areaId = sessionStorage.getItem("areaId");
      this.projectName = sessionStorage.getItem("projectName");
      this.courseCount = sessionStorage.getItem("courseCount");
      this.trainTypeId = sessionStorage.getItem("trainTypeId");
      this.projectSource = sessionStorage.getItem("projectSource");
      this.auditState = sessionStorage.getItem("auditState");
      this.getTableHeight();
      this.getcompleteList()
      this.getareatree();
      // if (this.projectSource!= '20') {

      // }
    },

    /**
     * 导出班级学员
     */
    exportClassStudent() {
      this.$confirm(
        "导出数据仅限于业务范围内使用，不得用于其它用途，如因个人原因导致数据泄露，由个人承担法律责任。",
        "导出提示",
        {
          confirmButtonText: "导出",
          confirmButtonClass: "confirmButtonClass",
          cancelButtonText: "取消",
        }
      )
        .then(() => {
          const params = {
            pageNum: this.pageNum,
            pageSize: this.pageSize,
            projectId: this.projectId,
          };
          if (this.userName) {
            params.userName = this.userName;
          }
          if (this.idcard) {
            params.idcard = this.idcard;
          }
          if (this.mobile) {
            params.mobile = this.mobile;
          }
          if (this.authenticationState) {
            params.authentication = this.authenticationState;
          }
          if (this.completeState) {
            params.completeState = this.completeState;
          }
          if (this.date) {
            params.startDate = this.date[0];
            params.endDate = this.date[1];
          }
          this.$post("/biz/projectUser/projectUserReport", params).then(
            (res) => {
              if (res.status == "0") {
                let list = res.data;
                this.editPopupDialogVisible = false;
                if (!this.downloadItems.includes(list.taskId)) {
                  this.$store.dispatch("pushDownloadItems", list.taskId);
                } else {
                  this.$message.warning(
                    "[" + list.fileName + "]已经申请下载,请耐心等待"
                  );
                }
              } else {
                this.$message.error(res.message);
              }
            }
          );
        })
        .catch(() => {});
    },
    // 获取学习状态&& 学历
    getcompleteList() {
      const studylist = this.$setDictionary("LEARNINGSTATE", "list");
      const educationList = this.$setDictionary("EDUCATION", "list");
      const authentication = this.$setDictionary("AUTHENTICATION", "list");
      const list = [];
      const eduList = [];
      const authenticationList = [];
      for (const key in studylist) {
        list.push({
          value: key,
          label: studylist[key],
        });
      }
      for (const key in educationList) {
        eduList.push({
          value: key,
          label: educationList[key],
        });
      }
      for (const key in authentication) {
        authenticationList.push({
          value: key,
          label: authentication[key],
        });
      }
      this.educationList = eduList;
      this.complete = list;
      this.authenticationList = authenticationList;
    },
    //列表数据
    getData(pageNum) {
      // sessionStorage.setItem("refresh", 10);
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        projectId: this.projectId,
      };
      if (this.userName) {
        params.userName = this.userName;
      }
      if (this.idcard) {
        params.idcard = this.idcard;
      }
      if (this.mobile) {
        params.mobile = this.mobile;
      }
      if (this.completeState) {
        params.completeState = this.completeState;
      }
      if (this.authenticationState) {
        params.authentication = this.authenticationState;
      }
      if (this.date) {
        params.startDate = this.date[0];
        params.endDate = this.date[1];
      }
      this.doFetch({
        url: "/biz/projectUser/listPage",
        params,
        pageNum,
      });
    },
      // 从本班移除
    handleDel(userId) {
      this.doDel({
        url: "/biz/projectUser/removeCourseUser",
        msg: "你确定要将该学员从本班移除吗？",
        ps: {
          type: "post",
          data: { userId, projectId: this.projectId },
        },
      });
    },
    lookstudent(projectUserCourseId, authentication, stu) {
      if (authentication == "20") {
        this.disabled = true;
        this.editshow = false;
        this.disabledcard = true;
      } else {
        this.editshow = true;
        this.disabled = false;
        this.disabledcard = true;
      }

      this.authentication = authentication;
      this.Form.newCoursestudy = [];
      this.$forceUpdate();
      this.projectUserCourseId = projectUserCourseId;
      this.stu = stu;
      this.dialogTitle = "查看学员信息";
      this.dialogVisible = true;
      this.$post("/biz/projectUser/queryCourseUser", { projectUserCourseId })
        .then((ret) => {
          let records = ret.data;
          this.Form.studentname = records.userName;
          this.Form.idCard = records.idcard;
          this.Form.sex = records.sex;
          this.Form.mobile = records.mobile;
          this.Form.email = records.email;
          this.Form.company = records.compName || "";
          this.Form.compId = records.compId || "";
          this.Form.educationLevel = records.educationLevel || "";
          this.Form.userId = records.userId || "";
          this.$forceUpdate();
        })
        .catch((err) => {
          return;
        });
    },
    //學習記錄
      Learningrecords(row) {
      sessionStorage.setItem("row", JSON.stringify(row));
      this.$router.push({
        path: "/web/Learningrecords",
      });
    },
     doClose() {
      this.dialogVisible = false;
      this.$refs["Form"].resetFields();
      this.Form = {
      };
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35 + 5) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
    //获取行政区划
    getareatree() {
      this.$post("/sys/area/heNanTree").then((ret) => {
        this.areatreeList = ret.data;
      });
    },
  },
};
</script>
<style lang="less" scoped>
.listWrap {
  .el-icon-arrow-down {
    font-size: 12px;
    &:before {
      content: "\e6df" !important;
    }
  }
  .el-tabs {
    flex: 1;
    display: flex;
    flex-direction: column;
    .el-tabs__content {
      flex: 1;
    }
  }
}
.Classitem {
  .el-form-item__content {
    display: flex;
  }
}
.el-button.is-disabled,
.el-button.is-disabled:focus,
.el-button.is-disabled:hover {
  background-color: transparent;
}

.confirmButtonClass {
  color: #fff !important;
  background-color: #5c6be8 !important;
  border-color: #5c6be8 !important;
}
.btnBox {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}
.exportdialog {
  .el-dialog__title {
    font-size: 16px;
  }
  .export-box {
    width: 100%;
    margin-bottom: 20px;
    h1 {
      padding: 0 5px 10px;
      display: flex;
      align-items: flex-end;
      font-size: 40px;
      border-bottom: 1px dashed #ccc;
      span {
        margin-left: 20px;
        font-size: 14px;
      }
    }
    > div {
      padding-left: 30px;
    }
    .el-button.is-disabled,
    .el-button.is-disabled:focus,
    .el-button.is-disabled:hover {
      color: #fff;
      background: #c3c3c3;
      border: none;
    }
  }
}
.docsDialog {
  .el-form-item {
    margin-bottom: 0.5rem;
  }
}
.studentNum {
  margin: 20px 0 10px;
  span {
    margin: 0 10px;
  }
}
</style>
