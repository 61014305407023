<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;" @click="goHerf()">班级列表</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a"
            >{{ projectName }}-详情</a
          >
        </span>
      </div>
      <div class="framePage-body framePage-tabs">
        <el-tabs
          v-model="activeName"
          :before-leave="beforeTabLeave"
          @tab-click="tabChange"
        >
          <el-tab-pane label="基本信息" name="first">
            <editClassBj ref="first" />
          </el-tab-pane>
          <el-tab-pane label="课程列表" name="second">
            <CourseListBj ref="second" />
          </el-tab-pane>
          <el-tab-pane label="学员列表" name="three">
            <StudentListBj ref="three" />
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>
<script>
import List from "@/mixins/List";
import editClassBj from "@/views/BjSunreal/editClassBj.vue";
import CourseListBj from "@/views/BjSunreal/CourseListBj.vue";
import StudentListBj from "@/views/BjSunreal/studentListBj.vue";
import { mapGetters } from "vuex";
export default {
  name: "ClassdetailsList",
  components: {
    editClassBj,
    CourseListBj,
    StudentListBj
  },
  mixins: [List],
  data() {
    return {
      activeName: "",
      projectName: "",
    };
  },
  mounted() {},
  computed: {
    ...mapGetters({
      userJson: "getUser",
    }),
  },
  methods: {
    init() {
      this.projectName = sessionStorage.getItem("projectName");
      this.projectId = sessionStorage.getItem("projectId");
      this.areaId = sessionStorage.getItem("areaId");
      this.trainTypeNamePath = sessionStorage.getItem("trainTypeNamePath");   
      this.projectSource = sessionStorage.getItem("projectSource");
      this.trainTypeId = sessionStorage.getItem("trainTypeId");
      const query = this.$route.query;
      if (query.active) {
        this.activeName = query.active;
      }
    
    },
    beforeTabLeave(newName) {
      this.$router.replace({
        path: this.$route.path,
        query: { active: newName },
      });
    },
    goHerf() {
          this.$router.back();
      }
  },
   watch: {
    activeName: function (nval, oval) {
      if (nval && nval != "first") {
        this.$refs[nval].getData();
        this.$refs[nval].getData();
        if (nval == "three") {
          this.$refs.three.openOnce();
        }
      }
    },
  },
};
</script>
<style lang="less" scoped>
.framePage-tabs {
  flex: 1;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0%;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  position: relative;
  background-color: #fff;
  padding: 1rem 0.675rem;
  box-sizing: border-box;
}
#pane-first {
  display: flex;
  flex: 1;
  overflow: hidden;
}
</style>
<style lang="less">
.framePage-tabs {
  .el-tabs {
    width: 100%;
  }
  .el-tabs__content {
    overflow-y: auto;
  }
}
</style>
